import { Suspense } from 'react';
import { Switch, Route } from "react-router-dom";
import { PageNotAuthen } from 'Pages';
import { CustomRoute, Loading } from 'Components';
import { ROUTES } from 'Routes/navigation';
import { AdminPage } from 'Pages/Admin/AdminPage';
import './App.scss';

export default function App() {
	return (
		<Suspense fallback={<Loading />}>
				<Switch>
					{ROUTES.map((routeConfig) => (
						<CustomRoute key={routeConfig.path} isProtected={routeConfig.isProtected} {...routeConfig} />
					))}
					<Route path="/admin" component={AdminPage} />
					<Route path="*" component={PageNotAuthen} />
				</Switch>
		</Suspense>
	);
}
